import { initMyBusinessSingleSPA } from "./scripts/init";
export { init } from "./scripts/init";
export { trackCustomPageView } from "./scripts/custom-page-view";

export async function bootstrap(): Promise<void> {
  return;
}

export async function mount(): Promise<void> {
  initMyBusinessSingleSPA();
}

export async function unmount(): Promise<void> {
  return;
}
